import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import classnames from 'classnames'
import { motion } from 'framer-motion'
import { ChevronLeft, ChevronRight, LogOut01 } from '@untitled-ui/icons-react'

import { logout } from '@app/app/auth/actions/authActions'
import { Body, Divider } from '@app/components'
import { ImageContent } from '@app/config/constants/ImageContent'
import { navMenu, RouteType } from '@app/config/router/navMenu'
import { Pages } from '@app/config/router/PagesEnum'
import { useTranslation } from '@app/locales'
import { createClassStore } from '@app/modules/classroom/store/createClassStore'
import { imagesIdToPicture } from '@app/modules/classroom/utils/imagesIdToPicture'
import { dialogStateStore } from '@app/modules/learningPlan/store/dialogStateStore'

import { useClassrooms } from '../../data/useClassrooms'
import { checkUrlIsActive } from '../../utils/checkUrlIsActive'
import { ClassroomNavigationItem } from './components/ClassroomNavigationItem'
import { CollapsibleWrapper } from './components/CollapsibleWrapper'
import { NavigationItem } from './components/NavigationItem'
import { NewClassNavigationItem } from './components/NewClassNavigationItem'
import { UserInfo } from './components/UserInfo'

export const SideNavigation = () => {
  const isExpanded = dialogStateStore.useStore((store) => store.sideMenu.isOpen)
  const location = useLocation()
  const { t } = useTranslation(['common', 'classroom'])

  const { classrooms } = useClassrooms()

  const topRoutes = useMemo(() => navMenu.filter((route) => route.position === 'top'), [])
  const middleRoutes = useMemo(() => navMenu.filter((route) => route.position === 'middle'), [])
  const classroomRoutes: RouteType[] =
    classrooms?.map((classroom) => ({
      Icon: () => <img src={imagesIdToPicture[classroom.subject][classroom.pictureId || 1]} />,
      label: classroom.name,
      to: `${Pages.CLASSROOM_DETAIL(classroom.id)}`
    })) || []

  const newClassRoute: RouteType = {
    label: t('newClass.new'),
    to: Pages.NEW_CLASS,
    position: 'top'
  }

  const bottomRoutes = useMemo(() => navMenu.filter((route) => route.position === 'bottom'), [])

  const showMenu = () => dialogStateStore.setIsOpen('sideMenu')
  const hideMenu = () => dialogStateStore.setIsClosed('sideMenu')

  //#region Handler
  const handleOnNewClassClick = () => {
    hideMenu()
    createClassStore.setDialogOpen(true)
  }

  const handleOnArrowClick = isExpanded ? hideMenu : showMenu
  //#endregion

  useEffect(() => {
    hideMenu()
  }, [location])

  return (
    <motion.div
      id="side-navigation"
      animate={isExpanded ? { translateX: 0 } : { translateX: -400 }}
      initial={{ translateX: '-40vw' }}
      className="fixed left-2 top-[2%] z-20 m-auto flex h-[95vh] w-96 max-w-[95vw] flex-col items-center rounded-2xl border-2 border-primary-600 bg-primary-900 shadow-2xl"
    >
      <div className="w-full pl-6 pt-4">
        <div className="flex">
          <h1 className="text-2xl font-bold tracking-wide text-white">FOXINO</h1>
          <p className="text-xs text-neutral-100">Beta</p>
        </div>
        <motion.div
          id="side-navigation-toggle"
          animate={isExpanded ? { translateX: 15 } : { translateX: 60 }}
          className="absolute right-0 top-12 z-20 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border-2 border-primary-600 bg-primary-900 p-1 hover:bg-primary-600"
          onClick={handleOnArrowClick}
        >
          {isExpanded ? (
            <ChevronLeft className="rounded-md text-primary-200" />
          ) : (
            <ChevronRight className="rounded-md text-primary-200" />
          )}
        </motion.div>
      </div>
      <aside
        className={classnames(
          'relative z-10 mb-4 flex h-full w-full flex-col overflow-hidden border-r-[1px] border-r-primary-800 duration-300'
        )}
      >
        <nav className="flex h-full max-h-[90vh] flex-col justify-between overflow-y-auto px-6 py-6">
          {/* Top routes */}
          <div>
            <div className="flex w-full flex-col gap-3">
              {topRoutes.map((route, index) => (
                <NavigationItem {...route} key={index} isExpanded isActive={checkUrlIsActive(route, location)} />
              ))}
              <div className="ml-1 flex items-center gap-4">
                <img src={ImageContent.classrooms.englandCrown} className="size-10" />
                <Body size="m" className="text-white">
                  {t('routes.classrooms')}
                </Body>
              </div>

              {classroomRoutes.map((route, index) => (
                <ClassroomNavigationItem
                  {...route}
                  key={index}
                  isExpanded
                  isActive={checkUrlIsActive(route, location)}
                />
              ))}

              <NewClassNavigationItem
                {...newClassRoute}
                isExpanded
                isActive={checkUrlIsActive(newClassRoute, location)}
                onClick={classroomRoutes.length === 0 ? undefined : handleOnNewClassClick}
              />

              <Divider className="my-2 bg-primary-700" />
            </div>
            <div className="group flex w-full flex-col">
              {middleRoutes.map((route, index) => {
                if (route.children) {
                  return (
                    <CollapsibleWrapper
                      key={index}
                      routes={route.children}
                      {...route}
                      isExpanded
                      isActive={checkUrlIsActive(route, location)}
                    />
                  )
                } else {
                  return (
                    <NavigationItem {...route} key={index} isExpanded isActive={checkUrlIsActive(route, location)} />
                  )
                }
              })}
            </div>
          </div>

          {/* Bottom routes */}
          <div className="flex w-full flex-col">
            {bottomRoutes.map((route, index) => (
              <NavigationItem {...route} key={index} isExpanded isActive={checkUrlIsActive(route, location)} />
            ))}

            <div onClick={logout}>
              <NavigationItem isExpanded label={t('logout')} isActive={false} Icon={LogOut01} to="#" />
            </div>

            <Divider className="my-2 bg-primary-700" />
            <UserInfo isExpanded />
          </div>
        </nav>
      </aside>
    </motion.div>
  )
}
