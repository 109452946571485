import { forwardRef } from 'react'
import classNames from 'classnames'

import { Label } from '../Typography'

type SizeType = 'small' | 'normal' | 'big'
type IconSide = 'left' | 'right'

export type BadgeVariantType =
  | 'gray'
  | 'primary'
  | 'error'
  | 'success'
  | 'warning'
  | 'blueGray'
  | 'blueLight'
  | 'blue'
  | 'indigo'
  | 'purple'
  | 'pink'
  | 'rose'
  | 'orange'

type Props = Omit<React.HTMLProps<HTMLDivElement>, 'children' | 'size'> & {
  size?: SizeType
  label?: string
  disabled?: boolean
  variant?: BadgeVariantType
  dot?: boolean
  imageUrl?: string
  icon?: React.ReactNode
  iconSide?: IconSide
  withoutBackground?: boolean
}

const sizeToTextStyle: Record<SizeType, string> = {
  small: 'text-xs ',
  normal: 'text-sm',
  big: 'text-sm'
}

type PaddingStyleType = 'label' | 'dot' | 'image' | 'icon-left' | 'icon-right' | 'icon-only'

const sizeToPaddingStyle: Record<PaddingStyleType, Record<SizeType, string>> = {
  label: {
    small: 'px-2 py-[2px]',
    normal: 'px-[10px] py-[2px]',
    big: 'px-3 py-1'
  },
  dot: {
    small: 'pl-[6px] pr-[8px] py-[2px]',
    normal: 'pl-[8px] pr-[10px] py-[2px]',
    big: 'pl-[10px] pr-[12px] py-1'
  },
  image: {
    small: 'pl-[3px] pr-[8px] py-[2px]',
    normal: 'pl-[4px] pr-[10px] py-[2px]',
    big: 'pl-[6px] pr-[12px] py-1'
  },
  'icon-left': {
    small: 'pl-[2px] pr-[8px] py-[2px]',
    normal: 'pl-[2px] pr-[10px] py-[2px]',
    big: 'pl-[6px] pr-[12px] py-1'
  },
  'icon-right': {
    small: 'pl-[8px] pr-[2px] py-[2px]',
    normal: 'pl-[10px] pr-[2px] py-[2px]',
    big: 'pl-[12px] pr-[6px] py-1'
  },
  'icon-only': {
    small: 'p-1',
    normal: 'p-[6px]',
    big: 'p-2'
  }
}

const variantToStyle: Record<BadgeVariantType, string> = {
  gray: 'bg-neutral-100 text-neutral-500',
  primary: 'bg-primary-300 text-primary-500',
  error: 'bg-error-300 text-error-500',
  warning: 'bg-secondary-200 text-secondary-400',
  success: 'bg-success-100 text-success-500',
  blueGray: 'bg-blue-200 text-blue-500',
  blueLight: 'bg-blue-50 text-blue-200',
  blue: 'bg-blue-400 text-blue-800',
  indigo: 'bg-indigo-200 text-indigo-500',
  purple: 'bg-purple-200 text-purple-500',
  pink: 'bg-pink-200 text-ping-500',
  rose: 'bg-rose-200 text-rose-500',
  orange: 'bg-orange-200 text-orange-500'
}

const variantToDotStyle: Record<BadgeVariantType, string> = {
  gray: 'bg-neutral-500',
  primary: 'bg-primary-500',
  error: 'bg-error-500',
  warning: 'bg-neutral-500',
  success: 'bg-success-500',
  blueGray: 'bg-blue-200',
  blueLight: 'bg-blue-50',
  blue: 'bg-blue-400',
  indigo: 'bg-indigo-200',
  purple: 'bg-purple-200',
  pink: 'bg-pink-200',
  rose: 'bg-rose-200',
  orange: 'bg-orange-200'
}

/**
 * @group Components
 * @category Component
 */
export const Badge = forwardRef(
  (
    {
      className,
      size = 'normal',
      variant = 'gray',
      iconSide = 'right',
      label,
      dot,
      imageUrl,
      icon,
      withoutBackground,
      ...restProps
    }: Props,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const iconOnly = !label
    const textSizeStyle = sizeToTextStyle[size]
    const colorStyle = variantToStyle[variant]
    const dotStyle = variantToDotStyle[variant]
    const paddingType = iconOnly
      ? 'icon-only'
      : dot
        ? 'dot'
        : imageUrl
          ? 'image'
          : icon && iconSide === 'left'
            ? 'icon-left'
            : icon && iconSide === 'right'
              ? 'icon-right'
              : 'label'
    const paddingStyle = sizeToPaddingStyle[paddingType][size]

    return (
      <div
        ref={ref}
        className={classNames(
          'flex h-full flex-row items-center justify-between',
          textSizeStyle,
          colorStyle,
          paddingStyle,
          withoutBackground && '!bg-transparent',
          iconOnly ? 'rounded-full' : 'rounded-2xl',
          !icon && 'gap-1',
          className
        )}
        {...restProps}
      >
        {imageUrl && (
          <div className="h-4 w-4">
            <img src={imageUrl} className="rounded-full object-cover" width={16} height={16} />
          </div>
        )}
        {dot && <span className={classNames('h-[6px] w-[6px] rounded-full', dotStyle)} />}
        {icon && iconSide === 'left' && <div className="flex h-5 w-5 items-center">{icon}</div>}
        {label && (
          <Label size="l" weight="medium">
            {label}
          </Label>
        )}
        {icon && iconSide === 'right' && <div className="mx-1 flex h-5 items-center">{icon}</div>}
      </div>
    )
  }
)
