import { createClient } from 'graphql-ws'
import { v4 } from 'uuid'
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  ServerParseError,
  split
} from '@apollo/client'
import { ErrorLink } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { ServerError } from '@apollo/client/link/utils/throwServerError'
import { getMainDefinition } from '@apollo/client/utilities'

import { logout } from '@app/app/auth/actions/authActions'
import { authStore } from '@app/app/auth/store/authStore'
import { httpStatusCode } from '@app/model/common/HTTPStatusCodeEnum'

import { apolloStore } from '../../modules/common/store/apolloStore'
import { Constants } from '../constants/Constants'

export const initApolloClient = (): void => {
  const apolloClient = getApolloClient()

  apolloStore.setApolloClient(apolloClient)
}

type ReturnType = ApolloClient<NormalizedCacheObject>

const getApolloClient = (): ReturnType => {
  const cache = new InMemoryCache({})

  const errorLink = new ErrorLink((error) => {
    if (hasStatusCode(error.networkError) && error.networkError.statusCode === httpStatusCode.unauthorized) {
      logout()
    }
  })

  const customFetch: WindowOrWorkerGlobalScope['fetch'] = async (uri, options) => {
    if (!options) options = {}
    if (!options?.headers) {
      options.headers = {}
    }

    const authToken = authStore.useStore.getState().accessToken || ''

    options.headers = {
      ...options.headers,
      ...(authToken && { Authorization: `Bearer ${authToken}` }),
      'trace-id': `${v4()}`
    }

    return fetch(uri, options)
  }

  const httpLink = new HttpLink({
    uri: Constants.GRAPHQL_QUERY_URL,
    fetch: customFetch
  })

  let activeSocket, timedOut
  const websocketLink = new GraphQLWsLink(
    createClient({
      url: Constants.GRAPHQL_SUBSCRIPTIONS_URL,
      connectionParams: { authToken: authStore.useStore.getState().accessToken || '' },
      keepAlive: 10_000,
      on: {
        connected: (socket) => {
          console.info('websocket client TP connected!')
          activeSocket = socket
        },
        closed: () => {
          console.info('websocket client TP closed!')
        },
        error: () => {
          console.info('websocket client TP received an error!')
        },
        ping: (received) => {
          if (!received)
            // sent
            timedOut = setTimeout(() => {
              if (activeSocket.readyState === WebSocket.OPEN) {
                activeSocket.close(4408, 'Request Timeout')
              }
            }, 5_000) // wait 5 seconds for the pong and then close the connection
        },
        pong: (received) => {
          if (received) {
            clearTimeout(timedOut) // pong is received, clear connection close timeout
          }
        }
      }
    })
  )

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value

  // queries and mutations will use HTTP as normal, subscriptions will use WebSocket
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    websocketLink,
    ApolloLink.from([errorLink, httpLink])
  )

  return new ApolloClient({
    connectToDevTools: true,
    cache,
    link: splitLink,
    defaultOptions: {
      watchQuery: {
        nextFetchPolicy: 'cache-and-network'
      }
    }
  })
}

const hasStatusCode = (networkError: any): networkError is ServerError | ServerParseError => {
  return Boolean(networkError && networkError.statusCode)
}
