import { Trans } from 'react-i18next'

import { Button, Heading, Label } from '@app/components'
import { ImageContent } from '@app/config/constants/ImageContent'
import { useTranslation } from '@app/locales'

import { logout } from '../actions/authActions'

export const IncorrectRole = () => {
  const { t } = useTranslation(['common', 'error'])

  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <div className="flex max-w-[500px] flex-col items-center justify-center gap-4 text-center text-neutral-400">
        <img src={ImageContent.WrongRights} width={400} className="-mt-8" />
        <Heading size="l">{t('notSufficientRole')}</Heading>
        <div>
          <Trans
            ns={'error'}
            parent={Label}
            className="text-lg"
            i18nKey="notSufficientRoleDescription"
            components={[<span className="text-white" />]}
          />
        </div>
        <div className="flex items-center gap-1">
          <Label>{t('waitList')} </Label>
          <a href="http://www.foxino.com" className="text-neutral-100 underline">
            <Label>www.foxino.com</Label>
          </a>
        </div>
        <Button onClick={logout}>{t('logout')}</Button>
      </div>
    </div>
  )
}
